import { t } from "@/i18n-js/instance";
import { ErrorBoundary } from "@/react/components/shared/ErrorPages/ErrorBoundary";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { TiptapEditor } from "@circle-react-uikit/TipTap";
import { messageTextClasses } from "./messageTextClasses";

export interface TipTapMessageBodyProps {
  editedAt?: any;
  isInitialPage?: boolean;
  isPinnedMessageAtTop?: boolean;
  rich_text_body?: any;
  chatRoomAccessArr?: Array<any>;
  shouldShowPreviewBlocks?: boolean;
}

export const TipTapMessageBody = ({
  editedAt,
  isInitialPage = false,
  isPinnedMessageAtTop = false,
  rich_text_body,
  chatRoomAccessArr,
  shouldShowPreviewBlocks = true,
}: TipTapMessageBodyProps) => {
  if (!rich_text_body) {
    return null;
  }

  return (
    <ErrorBoundary
      renderFunc={() => (
        <Typography.LabelSm color="text-v2-danger">
          {t("messaging.message_error")}
        </Typography.LabelSm>
      )}
    >
      <div
        className={classNames(messageTextClasses, {
          "pinned-message-body": isPinnedMessageAtTop,
        })}
        data-testid="message-text"
      >
        <TiptapEditor
          editable={false}
          rich_text_body={rich_text_body}
          shouldScrollIntoView={isInitialPage}
          shouldRefetch
          isUpdate={isPinnedMessageAtTop}
          editedAt={editedAt}
          chatRoomAccessArr={chatRoomAccessArr}
          shouldShowPreviewBlocks={shouldShowPreviewBlocks}
        />
      </div>
    </ErrorBoundary>
  );
};
