import { ErrorBoundary } from "@/react/components/shared/ErrorPages/ErrorBoundary";
import { ProfileAboutComponent } from "./ProfileAboutComponent";
import type { ProfileAboutComponentProps } from "./ProfileAboutComponent";
import { ProfileError } from "./ProfileError";

export const ProfileAbout = (props: ProfileAboutComponentProps) => (
  <ErrorBoundary renderFunc={ProfileError}>
    <ProfileAboutComponent {...props} />
  </ErrorBoundary>
);
